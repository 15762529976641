import { Select, Dropdown } from 'antd';
import { AiOutlineDown } from 'react-icons/ai';
import { useHistory } from 'react-router';

import PriceFilter from './PriceFilter';
import ProductTile from './ProductTile';
import { PRODUCTS } from '../../util/products';

const SORT_OPTIONS = [
    'A-Z',
    'Z-A',
    'Price, low-high',
    'Price, high-low',
    'Date, newest first',
    'Date, oldest first',
];

const Products = () => {
    const history = useHistory();

    return (
        <div className="products__container">
            {/* FILTERING & SORTING */}
            <div className="products__manipulation__container">
                {/* FILTERS */}
                <div className="products__manipulation__filters__container">
                    Filter:
                    <div style={{ marginLeft: 20, cursor: 'pointer' }}>
                        <Dropdown dropdownRender={PriceFilter} trigger={['click']}>
                            <div>
                                Price
                                <AiOutlineDown className="products__manipulation__filters__price-arrow" />
                            </div>
                        </Dropdown>
                    </div>
                </div>

                {/* SORT */}
                <div className="products__manipulation__sort__container">
                    <div>
                        Sort by:{' '}
                        <Select
                            defaultValue="A-Z"
                            style={{ width: 170 }}
                            bordered={false}
                            options={SORT_OPTIONS.map((opt) => ({ value: opt, label: opt }))}
                        />
                    </div>
                    <div className="products__manipulation__sort__total-products">0 product(s)</div>
                </div>
            </div>

            {/* PRODUCTS */}
            <div className="products__products__container">
                {PRODUCTS.map((product) => (
                    <div className="products__product__container">
                        <ProductTile
                            key={`product-tile-${product.name}`}
                            name={product.name}
                            price={product.price}
                            image={product.image}
                            onClick={() => history.push(`products/${product.name}`)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Products;
