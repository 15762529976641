import LabelledInput from '../reusable/LabelledInput';

const PriceFilter = () => {
    return (
        <div className="price-filter__container">
            <div className="price-filter__price-range__container">
                <LabelledInput
                    label="From"
                    width={100}
                    prefix="$"
                    value={undefined}
                    onChange={() => null}
                />
                <LabelledInput
                    label="To"
                    width={100}
                    prefix="$"
                    value={undefined}
                    onChange={() => null}
                />
            </div>

            <div className="price-filter__buttons__container">
                <div className="price-filter__buttons__button--reset">RESET</div>
                <div className="price-filter__buttons__button">APPLY</div>
            </div>
        </div>
    );
};

export default PriceFilter;
