import { useHistory } from 'react-router';
import { Tooltip } from 'antd';
import { CiShoppingBasket } from 'react-icons/ci';

const LINKS = [
    { name: 'Home', path: '/' },
    { name: 'Collections', path: '/collections' },
    { name: 'Products', path: '/products' },
];

const Navbar = () => {
    const history = useHistory();

    return (
        <div className="navbar__container">
            <div className="navbar__left-container">
                {/* STORE NAME */}
                <div className="header navbar__header">storefront</div>

                {/* LINKS */}
                {LINKS.map((link) => (
                    <div
                        key={link.name}
                        className="link navbar__link"
                        onClick={() => history.push(link.path)}
                    >
                        {link.name}
                    </div>
                ))}
            </div>

            <div className="navbar__right-container">
                <Tooltip title="Checkout" placement="bottomRight">
                    <CiShoppingBasket
                        className="navbar__cart"
                        onClick={() => history.push('cart')}
                    />
                </Tooltip>
            </div>
        </div>
    );
};
export default Navbar;
