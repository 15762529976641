import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const LabelledSelect = ({
    label,
    placeholder,
    value,
    onChangeAction,
    withValidation,
    isValid,
    isDisabled,
    options,
    noTopMargin,
    customWidth,
    size,
}) => {
    return (
        <div style={customWidth ? { width: customWidth } : { width: '100%' }}>
            {label ? (
                <div className="labelled-select__label" style={noTopMargin ? { marginTop: 0 } : {}}>
                    {label}
                </div>
            ) : null}
            <Select
                placeholder={placeholder}
                value={value}
                style={{ width: '100%' }}
                size={size || 'middle'}
                onChange={(value) => onChangeAction(value)}
                status={withValidation ? (isValid ? null : 'error') : null}
                disabled={isDisabled}
            >
                {options.map((option, idx) => (
                    <Option key={`option_${idx}`} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        </div>
    );
};
export default LabelledSelect;
