import { useHistory } from 'react-router';

import ProductTile from '../products/ProductTile';

import HatSample from '../../assets/mocks/storefront_hat_sample.png';
import ShirtSample from '../../assets/mocks/storefront_shirt_sample.png';
import BackpackSample from '../../assets/mocks/storefront_backpack_sample.png';
import ShirtAsh from '../../assets/mocks/storefront_shirt_ash.png';

const COLLECTIONS = [
    { name: 'hats', image: <img src={HatSample} alt="hat" /> },
    { name: 'shirts', image: <img src={ShirtSample} alt="shirt" /> },
    { name: 'backpacks', image: <img src={BackpackSample} alt="backpack" /> },
];

const Collections = () => {
    const history = useHistory();

    return (
        <div className="collections__container">
            {/* COLLECTION TILES */}
            <div className="collections__tiles__container">
                {COLLECTIONS.map((collection) => (
                    <div
                        key={`collection-tile-${collection.name}`}
                        className="collections__tile__container"
                    >
                        <div className="collections__tile__image">{collection.image}</div>
                        <div className="collections__tile__title">{collection.name}</div>
                    </div>
                ))}
            </div>

            {/* FEATURED PRODUCTS */}
            <div className="collections__featured__container">
                <div className="header collections__featured__header">Featured products</div>

                <div className="collections__featured__products">
                    <ProductTile
                        name="Ash Shirt"
                        price="19.99"
                        image={ShirtAsh}
                        onClick={() => history.push('products/ash shirt')}
                    />
                </div>
            </div>
        </div>
    );
};
export default Collections;
