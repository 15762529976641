import { Switch, Route } from 'react-router-dom';

import Home from '../components/home/Home';
import Collections from '../components/collections/Collections';
import Products from '../components/products/Products';
import Product from '../components/products/Product';
import Cart from '../components/checkout/Cart';
import Checkout from '../components/checkout/Checkout';

const ROUTES = [
    {
        path: '/collections',
        element: <Collections />,
    },
    {
        path: '/products/:product',
        element: <Product />,
    },
    {
        path: '/products',
        element: <Products />,
    },
    {
        path: '/cart',
        element: <Cart />,
    },
    {
        path: '/checkout',
        element: <Checkout />,
    },
    {
        path: '/',
        element: <Home />,
    },
];

const Routes = () => {
    return (
        <Switch>
            {ROUTES.map((route) => (
                <Route key={`route-${route.path}`} path={route.path}>
                    {route.element}
                </Route>
            ))}
        </Switch>
    );
};
export default Routes;
