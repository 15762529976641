const ProductTile = ({ name, price, image, onClick }) => {
    return (
        <div className="product-tile__container" onClick={onClick ?? null}>
            <div className="product-tile__image__container">
                <img src={image} alt={name} style={{ height: '100%' }} />
            </div>
            <div>{name}</div>
            <div className="product-tile__price">${price}</div>
        </div>
    );
};
export default ProductTile;
