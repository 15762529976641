import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { BiSad } from 'react-icons/bi';
import { CiShoppingBasket } from 'react-icons/ci';

import { PRODUCTS } from '../../util/products';

const Product = ({ match }) => {
    const { product } = match.params;
    const [displayedProduct, setDisplayedProduct] = useState(null);

    useEffect(() => {
        const requestedProduct = PRODUCTS.find(
            (prod) => prod.name.toUpperCase() === product.toUpperCase()
        );
        if (requestedProduct) {
            setDisplayedProduct(requestedProduct);
        }
    }, [product]);

    return (
        <>
            {/* NO PRODUCT */}
            {!displayedProduct && (
                <div className="product__error-state">
                    <BiSad className="product__error-state__icon" />
                    <div className="header">
                        Oops, something went wrong. This product doesn't exist.
                    </div>
                </div>
            )}

            {/* PRODUCT */}
            {displayedProduct && (
                <div className="product__container">
                    <div className="product__image__container">
                        <img
                            className="product__image__image"
                            src={displayedProduct.image}
                            alt={displayedProduct.name}
                        />
                    </div>
                    <div className="product__description__container">
                        <div className="header">{displayedProduct.name}</div>
                        <div className="product__description__price">${displayedProduct.price}</div>

                        <div className="product__description__description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever
                            since the 1500s, when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                        </div>

                        <div className="product__description__add-to-cart">
                            Add to cart
                            <CiShoppingBasket className="product__description__add-to-cart__icon" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default withRouter(Product);
