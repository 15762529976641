import axios from 'axios';

import { getBaseUrl } from '../util/api';

export class StorefrontAPI {
    static fetchStoreCustomizations() {
        return new Promise(async (resolve, reject) => {
            const storeURL = window.location.hostname;
            const config = {
                url: `${getBaseUrl()}/storefront/customization?storeURL=${storeURL}`,
                method: 'get',
            };

            try {
                const { data } = await axios(config).catch((e) => {
                    throw e;
                });

                resolve(data);
            } catch (e) {
                console.error(
                    `Error fetching store customizations: ${JSON.stringify(e.response.data.errors)}`
                );
                reject(e);
            }
        });
    }
}

export default StorefrontAPI;
