import ShirtAsh from '../assets/mocks/storefront_shirt_ash.png';
import ShirtBlack from '../assets/mocks/storefront_shirt_black.png';
import ShirtBlue from '../assets/mocks/storefront_shirt_blue.png';
import ShirtDasiy from '../assets/mocks/storefront_shirt_daisy.png';
import ShirtGreen from '../assets/mocks/storefront_shirt_green.png';
import ShirtOrange from '../assets/mocks/storefront_shirt_orange.png';
import ShirtRed from '../assets/mocks/storefront_shirt_red.png';

export const PRODUCTS = [
    { name: 'Ash Shirt', price: 19.99, image: ShirtAsh },
    { name: 'Black Shirt', price: 18.99, image: ShirtBlack },
    { name: 'Blue Shirt', price: 19.99, image: ShirtBlue },
    { name: 'Daisy Shirt', price: 21.99, image: ShirtDasiy },
    { name: 'Green Shirt', price: 21.99, image: ShirtGreen },
    { name: 'Orange Shirt', price: 21.99, image: ShirtOrange },
    { name: 'Red Shirt', price: 19.99, image: ShirtRed },
];
