import { useState } from 'react';
import { useHistory } from 'react-router';
import { Table } from 'antd';

import { CART_COLUMNS } from './cartUtil';
import { PRODUCTS } from '../../util/products';
import { toTwoDp } from '../../util/formatters';

export const TEMP_CART_DATA = [
    { ...PRODUCTS[0], qty: 2 },
    { ...PRODUCTS[3], qty: 1 },
    { ...PRODUCTS[4], qty: 1 },
];

const Cart = () => {
    const history = useHistory();
    const [cartData, setCartData] = useState(TEMP_CART_DATA);

    const getTotal = () => {
        let total = 0;
        cartData.forEach((product) => {
            const productTotal = +toTwoDp(product.price * product.qty);
            total += productTotal;
        });

        return toTwoDp(total);
    };

    return (
        <div className="cart__container">
            <div className="header">Your cart</div>

            {/* CART PRODUCTS */}
            <div className="cart__table__container">
                <Table
                    columns={CART_COLUMNS(cartData, setCartData)}
                    dataSource={cartData}
                    pagination={false}
                />
            </div>

            {/* TOTALS */}
            <div className="cart__totals__container">
                <div className="cart__totals__total">Cart total: ${getTotal()}</div>
                <div className="cart__totals__checkout" onClick={() => history.push('checkout')}>
                    CHECKOUT
                </div>
            </div>
        </div>
    );
};
export default Cart;
