import React from 'react';
import { useHistory } from 'react-router';

import BackgroundImage from '../../assets/background_clothes.jpg';

const Home = () => {
    const history = useHistory();

    return (
        <div className="home__container">
            {/* BACKROUND IMAGE */}
            <div className="home__background-image__container">
                <img
                    src={BackgroundImage}
                    alt="backgroundImage"
                    className="home__background-image__image"
                />
                <div className="home__background-image__blur" />
            </div>

            <div className="home__background-details__container">
                {/* TITLE */}
                <div className="home__title">Browse our latest products</div>

                {/* ACTION */}
                <div className="home__action" onClick={() => history.push('products')}>
                    Shop all
                </div>
            </div>
        </div>
    );
};
export default Home;
