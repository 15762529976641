import { InputNumber, Tooltip } from 'antd';
import { CiTrash } from 'react-icons/ci';

import { toTwoDp } from '../../util/formatters';

export const CART_COLUMNS = (displayedProducts, setDisplayedProducts) => [
    {
        title: 'PRODUCT',
        width: '50%',
        render: (_, record) => {
            return (
                <div className="cart__table__product-cell__container">
                    <img src={record.image} alt={record.name} style={{ height: '100%' }} />
                    <div className="cart__table__product-cell__details__container">
                        <div>{record.name}</div>
                        <div>${record.price}</div>
                    </div>
                </div>
            );
        },
    },
    {
        title: 'QUANTITY',
        width: '30%',
        render: (_, record) => {
            const productQty = displayedProducts.find((prod) => prod.name === record.name).qty;

            const updateProductQty = (newQty) => {
                const productIdx = displayedProducts.findIndex((prod) => prod.name === record.name);
                const copiedProducts = [...displayedProducts];
                copiedProducts[productIdx].qty = newQty;
                setDisplayedProducts(copiedProducts);
            };
            return (
                <div className="cart__table__qty-cell__container">
                    <InputNumber min={1} max={100} value={productQty} onChange={updateProductQty} />
                    <Tooltip title="Remove from cart" placement="bottomLeft">
                        <CiTrash className="cart__table__qty-cell__trash" />
                    </Tooltip>
                </div>
            );
        },
    },
    {
        title: 'TOTAL',
        width: '20%',
        render: (_, record) => {
            const { qty, price } = displayedProducts.find((prod) => prod.name === record.name);
            return `$${toTwoDp(qty * price)}`;
        },
    },
];
