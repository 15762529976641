import LabelledInput from '../reusable/LabelledInput';
import LabelledSelect from '../reusable/LabelledSelect';
import { TEMP_CART_DATA } from './Cart';

const Checkout = () => {
    return (
        <div className="checkout__container">
            {/* SHIPPING DETAILS */}
            <div className="checkout__shipping__container">
                {/* CONTACT DETAILS */}
                <div className="checkout__shipping__contact__container">
                    <LabelledInput
                        label="Contact"
                        placeholder="Email or phone number"
                        size="large"
                    />
                </div>

                {/* SHIPPING DETAILS */}
                <div className="checkout__shipping__single-container">
                    <LabelledSelect label="Country" size="large" options={['USA', 'Canada']} />
                </div>
                <div className="checkout__shipping__multi-container">
                    <LabelledInput label="First Name" size="large" width="49%" />
                    <LabelledInput label="Last Name" size="large" width="49%" />
                </div>
                <div className="checkout__shipping__single-container">
                    <LabelledInput label="Address" size="large" />
                </div>
                <div className="checkout__shipping__single-container">
                    <LabelledInput
                        label="Apartment, suite, etc."
                        size="large"
                        placeholder="(Optional)"
                    />
                </div>
                <div className="checkout__shipping__multi-container">
                    <LabelledInput label="City" size="large" width="32%" />
                    <LabelledInput label="State" size="large" width="32%" />
                    <LabelledInput label="ZIP" size="large" width="32%" />
                </div>
                <div className="checkout__shipping__ship__container">
                    <div className="checkout__shipping__ship__button">CONTINUE TO SHIPPING</div>
                </div>
            </div>

            {/* CART DETAILS */}
            <div className="checkout__cart__container">
                {/* CART ITEMS */}
                <div className="checkout__cart__items__container">
                    {TEMP_CART_DATA.map((cartLine) => (
                        <div className="checkout__cart__line__container">
                            <div className="checkout__cart__product__container">
                                <img
                                    src={cartLine.image}
                                    alt={cartLine.name}
                                    className="checkout__cart__product__img"
                                    style={{ height: '100%' }}
                                />
                                <div className="checkout__cart__product__name">{cartLine.name}</div>
                            </div>

                            <div>{`${cartLine.qty} x $${cartLine.price}`}</div>
                        </div>
                    ))}
                </div>

                {/* CART TOTALS */}
                <div className="checkout__cart__totals__container">
                    <div className="checkout__cart__totals__row">
                        <div>Subtotal</div>
                        <div>$0.00</div>
                    </div>
                    <div className="checkout__cart__totals__row">
                        <div>Shipping</div>
                        <div>Calculated at next step</div>
                    </div>
                    <div className="checkout__cart__totals__row">
                        <div className="checkout__cart__totals__total">Total</div>
                        <div className="checkout__cart__totals__total">$0.00</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Checkout;
