import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import StorefrontAPI from '../../api/storefront.api';

/** Component fetching custom CSS/JS scripts for the store and inserting them into HTML's
 * <head> tag
 */
const CustomScripts = () => {
    const [customStyles, setCustomStyles] = useState([]);
    const [customScripts, setCustomScripts] = useState([]);

    useEffect(() => {
        fetchCustomStyles();
    }, []);

    /** Fetches custom active CSS/JS scripts for the store, groups them and saves
     * them in the state */
    const fetchCustomStyles = async () => {
        const styleCustomizations = [];
        const scriptCustomizations = [];

        const storeCustomizations = await StorefrontAPI.fetchStoreCustomizations().catch(() => {});
        storeCustomizations
            .filter((customization) => customization.active)
            .forEach((customization) => {
                if (customization.type === 'CSS') styleCustomizations.push(customization);
                if (customization.type === 'JS') scriptCustomizations.push(customization);

                setCustomStyles([...styleCustomizations]);
                setCustomScripts([...scriptCustomizations]);
            });
    };

    return (
        <Helmet>
            {customStyles.map((customStyle) => (
                <style key={`style-customization-${customStyle._id}`}>{customStyle.details}</style>
            ))}
            {customScripts.map((customScript) => (
                <script key={`script-customization-${customScript._id}`}>
                    {customScript.details}
                </script>
            ))}
        </Helmet>
    );
};
export default CustomScripts;
