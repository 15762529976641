import { ConfigProvider } from 'antd';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import CustomScripts from './components/customScripts/CustomScripts';
import Routes from './util/routes';

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: 'Poppins',
                },
            }}
        >
            <CustomScripts />
            <Navbar />
            <div className="content">
                <Routes />
            </div>
            <Footer />
        </ConfigProvider>
    );
}

export default App;
