import { Input } from 'antd';

const LabelledInput = ({ label, value, onChange, placeholder, width, prefix, suffix, size }) => {
    const optionalProps = {};
    if (placeholder) optionalProps.placeholder = placeholder;
    if (prefix) optionalProps.prefix = prefix;
    if (suffix) optionalProps.suffix = suffix;

    return (
        <div className="labelled-input__container" style={width ? { width } : {}}>
            <div className="labelled-input__label">{label}</div>
            <Input
                {...optionalProps}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                size={size || 'middle'}
            />
        </div>
    );
};
export default LabelledInput;
